import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store';
import dayjs from 'dayjs';

const auth = (to, from, next) => {

  const url = `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_APP_ID}/oauth2/v2.0/authorize?client_id=${process.env.VUE_APP_AZURE_AUDIENCE_CODE}&response_type=id_token%20token&redirect_uri=${encodeURIComponent(process.env.VUE_APP_ENDPOINT+'/oauth')}&response_mode=fragment&scope=openid+profile+email+offline_access&state=${encodeURIComponent(to.fullPath)}&nonce=${process.env.VUE_APP_AZURE_AUDIENCE_CODE}`; //--> env

  if (!store.state.user_info.access_token) {
    window.location.href = url
  } else {
    const now = dayjs();
    console.log(now.format());
    const expires = dayjs(store.state.user_info.expired)
    console.log(expires.format());
    if (now.isAfter(expires)) {
      store.commit('removeUserInfo')
      window.location.href = url
    }
  }

  next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    redirect: 'dashboard',
    component: () => import('../views/app/Layout.vue'),
    beforeEnter: auth,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () =>  import('../views/app/Dashboard.vue')
      },{
        path: '/setting/users',
        name: 'UserManagement',
        component: () =>  import('../views/app/UserManagement.vue')
      },{
        path: '/setting/roles',
        name: 'RoleManagement',
        component: () =>  import('../views/app/RoleManagement.vue')
      },{
        path: '/setting/permissions',
        name: 'PermissionManagement',
        component: () =>  import('../views/app/PermissionManagement.vue')
      },{
        path: '/menu_mng/:brand_alias/menu',
        name: 'MenuManagement',
        component: () =>  import('../views/app/Menu/MenuManagement.vue')
      },{
        path: '/menu_mng/:brand_alias/menu/store',
        name: 'StoreMenuManagement',
        component: () =>  import('../views/app/Menu/StoreMenuManagement.vue')
      }, 
      {
        path: '/menu_mng/:brand_alias/menu/category',
        name: 'StoreCategoryManagement',
        component: () =>  import('../views/app/Menu/MenuCategoryManagement.vue')
      }, 
      {
        path: '/menu_mng/:brand_alias/menu/item',
        name: 'StoreItemManagement',
        component: () =>  import('../views/app/Menu/StoreItemManagement.vue')
      }, {
        path: '/menu_mng/:brand_alias/item',
        name: 'ItemManagement',
        component: () =>  import('../views/app/Menu/ItemManagement.vue')
      }, 
      {
        path: '/menu_mng/:brand_alias/item_option',
        name: 'ItemOptionManagement',
        component: () =>  import('../views/app/Menu/ItemOptionManagement.vue')
      },
      {
        path: '/menu_mng/:brand_alias/upsell',
        name: 'Upsell',
        component: () =>  import('../views/app/Menu/Upsell.vue')
      },
      {
        path: '/menu_mng/:brand_alias/upsellDetail/:mode/:upsell_id',
        name: 'UpsellDetail',
        component: () =>  import('../views/app/Menu/UpsellDetail.vue')
      },
      {
        path: '/fleet/fleet',
        name: 'FleetList',
        component: () =>  import('../views/app/Fleet/FleetList.vue')
      },{
        path: '/fleet/fleet_priority',
        name: 'FleetPriority',
        component: () =>  import('../views/app/Fleet/FleetPriority.vue')
      },{
        path: '/fleet/configs',
        name: 'FleetConfiguration',
        component: () =>  import('../views/app/Fleet/Config.vue')
      },{
        path: '/fleet/delivery_fee',
        name: 'DeliveryFee',
        component: () =>  import('../views/app/Fleet/DeliveryFee.vue')
      },{
        path: '/fleet/audit_log',
        name: 'AuditLog',
        component: () =>  import('../views/app/Fleet/AuditLog.vue'),
      },{
        path: '/fleet/audit_log/:id',
        name: 'AuditLogDetail',
        component: () =>  import('../views/app/Fleet/AuditLogDetail.vue')
      },{
        path: '/fleet/dashboard',
        name: 'DeliveryDashboard',
        component: () =>  import('../views/app/Fleet/DeliveryDashboard.vue')
      },{
        path: '/fleet/dl_cancel_summmary',
        name: 'DeliveryCancelSummary',
        component: () =>  import('../views/app/Fleet/DeliveryCancelSummary.vue')
      },{
        path: '/fleet/instant_cancel_dashboard',
        name: 'InstantCancellationDashboard',
        component: () =>  import('../views/app/Fleet/InstantCancellationDashboard.vue')
      },{
        path: '/fleet/dashboard/:id',
        name: 'DeliveryDashboardDetail',
        component: () =>  import('../views/app/Fleet/DeliveryDashboardDetail.vue')
      },{
        path: '/campaigns/dashboard',
        name: 'CampaignDashboard',
        component: () =>  import('../views/app/Campaign/CampaignDashboard.vue')
      },{
        path: '/campaigns/message',
        name: 'CampaignMessage',
        component: () =>  import('../views/app/Campaign/CampaignMessage.vue')
      },{
        path: '/campaigns/condition',
        name: 'CampaignCondition',
        component: () =>  import('../views/app/Campaign/CampaignCondition.vue')
      },{
        path: '/campaigns/coupon',
        name: 'Coupon',
        component: () =>  import('../views/app/Campaign/Coupon.vue')
      },{
        path: '/campaigns/coupon_code',
        name: 'CouponCode',
        component: () =>  import('../views/app/Campaign/CouponCode.vue')
      },{
        path: '/campaigns/campaign_ontop',
        name: 'CampaignOnTop',
        component: () =>  import('../views/app/Campaign/CampaignOnTop.vue')
      },
      {
        path: '/runner',
        name: 'RunnerDashboard',
        component: () =>  import('../views/app/Runner/RunnerDashboard.vue')
      },
      {
        path: '/runner/:id',
        name: 'RunnerDashboardDetail',
        component: () =>  import('../views/app/Runner/RunnerDashboardDetail.vue')
      },
      
      //Customer Service
      {
        path: '/customer/customer',
        name: 'CustomerList',
        component: () =>  import('../views/app/Customer/CustomerList.vue')
      },
      {
        path: '/customer/audit',
        name: 'Audit',
        component: () =>  import('../views/app/Customer/Audit.vue')
      },
      
      {
        path: '/customer/blacklist',
        name: 'BlackList',
        component: () =>  import('../views/app/Customer/BlackList.vue')
      },
      {
        path: '/customer/config',
        name: 'Config',
        component: () =>  import('../views/app/Customer/Config.vue')
      },
      {
        path: '/customer/platform',
        name: 'Platform',
        component: () =>  import('../views/app/Customer/Platform.vue')
      },
      {
        path: '/customer/subscription',
        name: 'Subscription',
        component: () =>  import('../views/app/Customer/Subscription.vue')
      },
      
      //--End of Customer Service
    ]
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/static/Logout.vue')
  },
  {
    path: '/oauth',
    name: 'Auth',
    component: () => import('../views/static/Auth.vue')
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/static/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
