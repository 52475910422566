/* eslint-disable */
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

export default createStore({
  state: {
    user_info: {
      username: null,
      token_type: null,
      access_token: null,
      expired: null,
      routes: [],
      permissions: []
    },
  },
  mutations: {
    addUserInfo(state:any, data) {
      state.user_info.id = data.id
      state.user_info.username = data.username
      state.user_info.token_type = data.token_type
      state.user_info.access_token = data.access_token
      state.user_info.expired = data.expired
      state.user_info.routes = data.routes
      state.user_info.permissions = data.permissions
    },
    removeUserInfo(state) {
      state.user_info.id = null
      state.user_info.username = null
      state.user_info.token_type = null,
      state.user_info.access_token = null
      state.user_info.expired = null,
      state.user_info.routes = null,
      state.user_info.permissions = null
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [new VuexPersistence({ key: 'state' }).plugin]
})
