import './assets/scss/stylesheet.scss';

import axios from 'axios';
import { createApp } from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App)
app.use(store)
app.use(router)

axios.interceptors.request.use((request) => {
    if (store.state.user_info.access_token) {
        request.headers.authorization = `${store.state.user_info.token_type} ${store.state.user_info.access_token}`
    }
    return request
})

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.data.statusCode === 401) {
        store.commit('removeUserInfo');
        const url = `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_APP_ID}/oauth2/v2.0/authorize?client_id=${process.env.VUE_APP_AZURE_AUDIENCE_CODE}&response_type=id_token%20token&redirect_uri=${encodeURIComponent(process.env.VUE_APP_ENDPOINT + '/oauth')}&response_mode=fragment&scope=openid+profile+email+offline_access&state=${encodeURIComponent(router.currentRoute.value.path)}&nonce=${process.env.VUE_APP_AZURE_AUDIENCE_CODE}`;
        window.location.href = url;
        return;
    }
    return Promise.reject(error);
});

library.add(fas, far)

app.component('fa', FontAwesomeIcon)

// eslint-disable-next-line
app.config.globalProperties.$redirect = (routeName: string, params?: any) => {
    router.push({
        name: routeName,
        params: params
    })
}

app.mount('#app')
